
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("codecrafters-frontend/templates/concept-admin", function(){ return i("codecrafters-frontend/templates/concept-admin.hbs");});
d("codecrafters-frontend/controllers/concept-admin", function(){ return i("codecrafters-frontend/controllers/concept-admin.ts");});
d("codecrafters-frontend/routes/concept-admin", function(){ return i("codecrafters-frontend/routes/concept-admin.ts");});
d("codecrafters-frontend/templates/concept-admin/basic-details", function(){ return i("codecrafters-frontend/templates/concept-admin/basic-details.hbs");});
d("codecrafters-frontend/controllers/concept-admin/basic-details", function(){ return i("codecrafters-frontend/controllers/concept-admin/basic-details.ts");});
d("codecrafters-frontend/templates/concept-admin/blocks", function(){ return i("codecrafters-frontend/templates/concept-admin/blocks.hbs");});
d("codecrafters-frontend/controllers/concept-admin/blocks", function(){ return i("codecrafters-frontend/controllers/concept-admin/blocks.ts");});
d("codecrafters-frontend/templates/concept-admin/question", function(){ return i("codecrafters-frontend/templates/concept-admin/question.hbs");});
d("codecrafters-frontend/controllers/concept-admin/question", function(){ return i("codecrafters-frontend/controllers/concept-admin/question.ts");});
d("codecrafters-frontend/routes/concept-admin/question", function(){ return i("codecrafters-frontend/routes/concept-admin/question.ts");});
d("codecrafters-frontend/templates/concept-admin/questions", function(){ return i("codecrafters-frontend/templates/concept-admin/questions.hbs");});
d("codecrafters-frontend/controllers/concept-admin/questions", function(){ return i("codecrafters-frontend/controllers/concept-admin/questions.ts");});
d("codecrafters-frontend/routes/concept-admin/index", function(){ return i("codecrafters-frontend/routes/concept-admin/index.ts");});
